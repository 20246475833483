<script setup>
import { onMounted, ref } from 'vue';

defineProps(['value']);

const input = ref(null);

onMounted(() => {
  if (input.value.hasAttribute('autofocus')) {
    input.value.focus();
  }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <input
        class="block w-full rounded-md border-0 py-2 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm text-stone-900 ring-stone-300 placeholder:text-stone-400 focus:ring-region-forest"
        :value="value"
        @input="$emit('input', $event.target.value)"
        ref="input"
    />
</template>
